import React from "react";
import "./css/QuestionAnswerComponent.css";
import config from "../../config";
import { notification } from "antd";

const QuestionAnswerComponent = ({ question, answer }) => {
  const isJsonString = (str) => {
    if (typeof str !== "string") return false;
    str = str.trim();
    return str.startsWith("{") || str.startsWith("[");
  };

  const tryParseNonStandardJson = (str) => {
    try {
      str = str
        .replace(/([a-zA-Z0-9_]+)\s*:/g, '"$1":')
        .replace(/:\s*([a-zA-Z0-9_]+)/g, ': "$1"');

      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  };



  const isVideo = (text) => {
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    return (
      text &&
      typeof text === "string" &&
      videoExtensions.some((ext) => text.toLowerCase().endsWith(ext))
    );
  };

  const renderAnswer = (ans) => {
    if (!ans) {
      return <p>No answer available</p>;
    }

    if (typeof ans === "string" && ans.trim() === "[all above]") {
      return <p>All of the above</p>;
    }

    const isImage = (val) => {
      return /\.(jpeg|jpg|gif|png|svg)$/.test(String.prototype.trim.call(val));
    };

    if (typeof ans === "string" && isJsonString(ans)) {
      ans = tryParseNonStandardJson(ans);
    }

    if (typeof ans === "string") {
      if (ans.includes(".png") || ans.includes(".jpg")) {
        const imagePaths = ans
          .replace(/[\[\]]/g, "")
          .split(",")
          .map((path) => path.trim().replace(/\\/g, "/"));

        return (
          <div className="image-gallery">
            {imagePaths.map((path, index) => (
              <img
                key={index}
                src={`${config.imageUrl}${path}`}
                alt={`Answer ${index + 1}`}
                style={{ width: "45%", margin: "0 2.5%" }}
              />
            ))}
          </div>
        );
      }
    } else if (Array.isArray(ans)) {
      const imagePaths = ans.map((path) => path.trim().replace(/\\/g, "/"));

      return (
        <div className="image-gallery">
          {imagePaths.map((path, index) => (
            <img
              key={index}
              src={`${config.imageUrl}${path}`}
              alt={`Answer ${index + 1}`}
              style={{ width: "45%", margin: "0 2.5%" }}
            />
          ))}
        </div>
      );
    } else {
      return null;
    }
    if (
      typeof ans === "string" &&
      /^(\[#[0-9A-Fa-f]{6}(,\s*\#[0-9A-Fa-f]{6})*\])$/.test(ans)
    ) {
      function getContrastYIQ(hexColor) {
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq >= 128 ? "#000000" : "#FFFFFF";
      }

      const copyToClipboard = (text) => {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            notification("success", `Copied to clipboard: ${text}`);
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
      };

      const colorCodes = ans
        .replace(/[\[\]]/g, "")
        .split(",")
        .map((color) => color.trim());

      return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {colorCodes.map((colorCode, index) => (
            <div
              key={index}
              style={{
                background: colorCode,
                width: "22%",
                padding: "7px 10px",
                color: getContrastYIQ(colorCode),
                textAlign: "center",

                margin: "0 5px 15px",
                boxSizing: "border-box",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => copyToClipboard(colorCode)}
            >
              {colorCode} {/* Display the color code text */}
            </div>
          ))}
        </div>
      );
    }

    if (isImage(ans)) {
      return (
        <img
          src={`${config.imageUrl}${ans.replace(/\\/g, "/")}`}
          alt="Answer"
        />
      );
    }

    if (isVideo(ans)) {
      return (
        <video className="Question-Answer-Video" controls>
          <source src={`${config.imageUrl}${ans}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    if (typeof ans === "object" && !Array.isArray(ans)) {
      return (
        <div className="json-answer">
          {Object.keys(ans).map((key, index) => (
            <p key={index}>
              <strong>{key}:</strong>{" "}
              {Array.isArray(ans[key])
                ? ans[key].length === 0
                  ? "No data"
                  : ans[key].join(", ")
                : ans[key] || "No value"}
            </p>
          ))}
        </div>
      );
    }

    if (Array.isArray(ans)) {
      return ans.length > 0 ? (
        ans.map((item, index) => <div key={index}>{renderAnswer(item)}</div>)
      ) : (
        <p>No data available</p>
      );
    }

    return <p>{ans || "No answer available"}</p>;
  };

  return (
    <div className="question-answer-container">
      <div className="question">{question || "No question available"}</div>
      <div className="answer">{renderAnswer(answer)}</div>
    </div>
  );
};

export default QuestionAnswerComponent;
